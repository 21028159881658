<template>
    <div>
        <div>{{ organizationTypeString() }}
            <star-rating v-show="imgRate1Star" :rtl="$vs.rtl" :inline="true" :star-size="20" :read-only="true"
                         :show-rating="false" :rating="1"></star-rating>
            <star-rating v-show="imgRate2Star" :rtl="$vs.rtl" :inline="true" :star-size="20" :read-only="true"
                         :show-rating="false" :rating="2"></star-rating>
            <star-rating v-show="imgRate3Star" :rtl="$vs.rtl" :inline="true" :star-size="20" :read-only="true"
                         :show-rating="false" :rating="3"></star-rating>
            <star-rating v-show="imgRate4Star" :rtl="$vs.rtl" :inline="true" :star-size="20" :read-only="true"
                         :show-rating="false" :rating="4"></star-rating>
            <star-rating v-show="imgRate5Star" :rtl="$vs.rtl" :inline="true" :star-size="20" :read-only="true"
                         :show-rating="false" :rating="5"></star-rating>
        </div>
    </div>
</template>

<script>
import StarRating from 'vue-star-rating'

export default {
    components: {
        StarRating
    },
    data() {
        return {
            imgRate1Star: false,
            imgRate2Star: false,
            imgRate3Star: false,
            imgRate4Star: false,
            imgRate5Star: false,
        }
    },
    name: 'CellRendererIcon',
    methods: {
        organizationTypeString() {

            if (this.params.data.evaluateType == 1) {
                this.imgRate1Star = true;
            }
            if (this.params.data.evaluateType == 2) {
                this.imgRate2Star = true;
            }
            if (this.params.data.evaluateType == 3) {
                this.imgRate3Star = true;
            }
            if (this.params.data.evaluateType == 4) {
                this.imgRate4Star = true;
            }
            if (this.params.data.evaluateType == 5) {
                this.imgRate5Star = true;
            }
        }
    }
}
</script>

<style lang="scss" scpoped>

</style>
